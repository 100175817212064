.banner {
  position: relative;
  height: 100vh;
  background: url('/public/bbbbg.png') center/cover no-repeat;
}

.banner::before {
  content: "";
  background: inherit;
  filter: blur(5px); /* Apply blur effect to the pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
}

.banner-content {
  position: relative;
  /* z-index: 1; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.banner-title {
  color: #FFA500;
  font-size: 36px;
  margin: 0;
  text-align: center;
}

.banner-text {
  color: white;
  font-size: 24px;
  margin: 20px 0;
  text-align: center;
}

.linkServ{
  background-color: #5555555c;
  text-decoration: none;
  padding: 12px;
  border: 2px solid #555;
  border-radius: 7px;
  color: #FFA500;
}

 .linkServ:hover{
   background-color: #FFA500;
   color: white !important;
   transform: rotate(360deg);
 }
 
 
