.contact-form {
    text-align: center;
    padding: 20px;
    border: 0px solid #e0e0e0 !important;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #000000 !important;
    transition: transform 0.2s, box-shadow 0.2s;
    max-width: inherit !important;
  }
  
  .contact-form:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .contact-form h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .contact-form form {
    max-width: inherit !important;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    font-size: 16px;
  }
  
  .contact-form textarea {
    height: 120px;
  }
  
  .contact-form button {
    background-color: #2f4051 !important;
    /* color: #fff; */
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .contact-form button:hover {
    background-color: #2f4051;
  }
  
.success-message,
.error-message {
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  /* font-weight: bold; */
}

.success-message {
  background-color: #FFA500;
  color: white;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
}
  @media (max-width: 768px) {
    .contact-form form input,
    .contact-form form textarea,
    .contact-form button {
      width: 80%;
    }
    .contact-form form {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  }
  