/* Default styles */
.contact-page {
  text-align: center;
  font-size: 18px;
  color: white;
}

.contact-details {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
}

.address, .phone, .email {
  flex: 1;
  margin: 10px;
  padding: 20px;
  /* background-color: #f5f5f5; */
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  color: white;
  text-align: center;
}
.contact-container {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* background-color: #f8f8f8; */
  padding: 30px;
  border-radius: 10px;
}
/* Responsive styles */
@media (max-width: 768px) {
  .contact-details {
    flex-direction: column;
  }

  .address, .phone, .email {
    margin: 10px 0;
  }
  .contact-container {
    padding: 20px;
    flex-direction: column;
  }
}

/* Add more media queries as needed for different screen sizes */
.hr-line {
  border-top: 2px solid #555;
  margin: 20px auto;
  width: 60px;
}
.contact-details p{
  color: #555;
}