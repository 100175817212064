.journey-section {
    padding: 40px;
  }
  
  .journey-content {
    /* background: #f9f9f9; */
    padding: 20px;
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    text-align: center;
  }
  
  .journey-content h3 {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 10px;
  }
  
  .journey-content p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  


  .profile-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .profile-card {
     
    width: 274px; /* Adjust the width as needed */
    margin: 10px; /* Add margin for spacing between cards */
    text-align: center;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .profile-details {
    padding: 10px;
    color: #555;
    text-align: center;
  }
  
  .profile-details h3 {
    font-size: 1.2em;
    margin-bottom: 5px;
    color: white;
  }
  
  .profile-details p {
    font-size: 14px;
    margin-bottom: 0;
  }
  

  .contact-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* background-color: #f8f8f8; */
    padding: 30px;
    border-radius: 10px;
  }
  
  .contact-container h2 {
    font-size: 1.2em;
    color: #555;
    margin: 0 0 20px 0;
  }
  
  .contact-form {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .contact-form textarea {
    height: 150px;
  }
  
  .contact-form button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .contact-container {
      padding: 20px;
      flex-direction: column;
    }
  
    .contact-form {
      max-width: 90%;
    }
    
  }
  .contact-page{
      text-align: center;
  }
  .hr-line {
    border-top: 2px solid #555;
    margin: 20px auto;
    width: 60px;
  }
  .AHcolor{
    font-size: 1.5rem;
  }