.carousel-text {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    color: #555; /* Change the text color as desired */
    /* background-color: #f4f4f4; Change the background color as desired */
    border-radius: 8px; /* Add rounded corners */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  }
  
  /* Style the active item in the carousel differently */
  .carousel .carousel-slider .control-dots .dot.selected {
    background-color: #007bff; /* Change the active dot color as desired */
  }
  
.containerForCar{
    text-align: center;
    display: contents;
}
.hr-line {
  border-top: 2px solid #555;
  margin: 20px auto;
  width: 60px;
}  
.containerForCar h2 {
    margin-bottom: 20px;
    color: white;
  }
  .containerForCar p{
    color: #555;
  }
  .tech-stack{
      margin-top: 5%;
      margin-bottom: 10%;
  }