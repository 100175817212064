/* Style the content-cards container */
.content-cards {
    display: flex;
    justify-content: space-between;
}
/* Assuming your card container has a class of .card-container */
.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Three cards in a row */
    gap: 20px; /* Adjust the spacing between cards */
  }
  
  /* Individual card styles */
 
  
/* Style each content card */
.content-card {
    /* background-color: #f4f4f4; */
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    padding: 20px;
    width: 80%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

/* Add hover effect */
.content-card:hover {
    transform: scale(1.05);
}

/* Style the card images */
.image img {
    width: 200px; /* Set the desired width */
    height: 200px; /* Set the desired height */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    border-radius: 50%;
    margin-left: 13%;
}

/* Style the card titles */
.info{
    text-align: center;
}
.info h4 {
    font-size: 1.5em;
    margin: 10px 0;
}

/* Style the card descriptions */
.info p {
    font-size: 1em;
}

/* Media query for responsiveness on smaller screens */
@media (max-width: 768px) {
    .content-cards {
        flex-direction: column;
        align-items: center;
    }
    
    .content-card {
        width: 80%;
        margin-bottom: 20px;
    }
}
/* ContentCard.css */

.content-card {
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    padding: 16px;
    margin: 16px;
     
    align-items: center;
  }
  
  /* .content-card .image img {
    width: 100px;
    height: 100px;
    margin-right: 16px;
    border-radius: 50%;
  } */
  
  .content-card .info {
    flex: 1;
  }
  
  .content-card h4 {
    font-size: 1.2rem;
    margin: 0;
    color: white;
    margin-top: 5px;
  }
  
  .content-card p {
    font-size: 1rem;
    color: #555;
  }

  