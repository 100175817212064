/* Navbar Styles */
 

.navbar-scroll {
  background-color: #333333b8 !important; /* Change this to the desired background color */
  /* Other styles for the scrolled navbar */
}


.navbar {
  background-color: transparent; /* Change this to your desired background color */
  color: #fff; /* Change this to your desired text color */
  padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1350px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.nav-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav-links li {
  margin: 0 20px;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  transition: color 0.3s;
}

.nav-links a:hover  {
  color: #FFA500; /* Change this to your desired link hover color */
}
.nav-links .isActive  {
  color: #FFA500 !important; /* Change this to your desired link hover color */
}
.logo {
  color: #FFA500; /* Change this to your desired link hover color */
  cursor: pointer;
}
.menu-icon {
  display: none; /* Hidden by default on larger screens */
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: background-color 0.3s;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-links ul {
    display: none; /* Hide the navigation links */
    flex-direction: column;
    text-align: center;
    background-color: #333; /* Change this to your desired background color */
    position: absolute;
    top: -24px !important;
    left: 0;
    right: 0;
    transition: all 0.3s;
    z-index: 1;
  }

  .nav-links.active ul {
    display: flex; /* Show the navigation links */
  }

  .nav-links.active li {
    padding: 15px 0;
    width: 100%;
  }

  .menu-icon {
    display: flex; /* Display the menu icon on smaller screens */
  }

  .bar.open {
    background-color: #FFA500; /* Change this to your desired open menu icon color */
  }
}


/* Hamburger menu icon */
.menu-icon {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s;
  border-radius: 2px;
}

/* Display navigation links on mobile */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #333;
    width: 100%;
    padding: 10px 0;
    transition: transform 0.3s ease-in-out;
    transform: translateY(-100%);
  }

  .menu-icon {
    display: flex;
  }

  .bar.open:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
  }
  .bar.open:nth-child(2) {
    opacity: 0;
  }
  .bar.open:nth-child(3) {
    transform: translateY(-5px) rotate(-45deg);
  }

  .nav-links.active {
    display: flex;
    transform: translateY(0);
  }
  
}
.contact{
  padding: 6px;
  background: #FFA500;
  border-radius: 12px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
/* 
.contact-dropdown {
  display: none;
  position: absolute;
  top: 73%;
  right: 61px;
  z-index: 1;
  background-color: #000000a8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: center;
  width: 79px;
  border-radius: 10px;
}

.contact-dropdown.open {
  display: block;
}

.contact-dropdown a {
  display: block;
  margin-bottom: 10px;
  color: white;
  text-decoration: none;
  transition: color 0.3s;
  cursor: pointer;
}

.contact-dropdown a:hover {
  color: #FFA500;
  cursor: pointer;
} */
.contact-dropdown {
   top: 77%;
  background-color: #000000a8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: center;
  width: auto;
  border-radius: 10px;
  position: absolute;
}

.contact-dropdown.open {
  display: block;
}

.contact-dropdown a {
  display: block;
  margin-bottom: 10px;
  color: white;
  text-decoration: none;
  transition: color 0.3s;
  cursor: pointer;
}

.contact-dropdown a:hover {
  color: #FFA500;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-dropdown {
    top: 100%;
    right: 36%;
  }
}
 