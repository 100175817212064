.footer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #333;
    color: #FFA500;
    font-size: 14px;
  }
  
  .footer .left {
    text-align: left;
  }
  
  .footer .right {
    text-align: right;
  }
  